<template>
    <div>
      <modal className="mr-0 px-10 w-full md:w-420px" ref="modal">
        <h5 class="mb-3">Update {{ staff.name }}'s Cash Wallet Transaction Limit</h5>
        <div class="flex flex-col gap-1 mb-10 text-gray-700">
          <p>
            This process sets a daily trasaction limit on the user's cash wallet.
          </p>
        </div>
  
        <template v-if="getFormError(form)">
          <div class="alert alert-red-soft mb-10">
            <span class="alert-icon">!</span>
            <span>{{ getFormError(form) }}</span>
          </div>
        </template>
  
        <form @submit.prevent="submit">
          <form-group
            v-model="form.data.amount.value"
            :form="form"
            name="amount"
            type="money"
          >
            Set Amount
          </form-group>
          
          <button
            type="submit"
            class="btn btn-blue btn-md w-full"
            :disabled="form.loading"
          >
            <sm-loader-white v-if="form.loading" />
            <span v-else>Set Limit</span>
          </button>
        </form>
      </modal>
      <modal></modal>
    </div>
  </template>
  
  <script>
  export default {
    props: {
      staff: Object,
    },
    data() {
      const form = this.$options.basicForm(['amount']);
      return { form };
    },
    methods: {
      close() {
        this.$refs.modal.close();
      },
      async submit() {
        if (!this.validateForm(this.form)) return false;
  
        this.form.error = null;
        this.form.loading = true;
        await this.sendRequest('admin.users.cash.addLimit', this.staff?.id, {
          data: {
            ...this.getFormData(this.form),
            user_id: this.staff.id,
          },
          success: () => {
            this.close();
            this.$success({
              title: 'Operation Successful',
              body: 'User wallet transaction limit set',
              button: 'Okay'
            });
            this.form = this.resetForm(this.form);
          },
          error: (error) => {
            this.form.error = error;
            this.mapFormErrors(this.form, error?.response?.data?.errors);
          },
        });
        this.form.loading = false;
      },
      open() {
        this.$refs.modal.open();
      },
    },
  };
  </script>
  